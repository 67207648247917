
import { hideLoadingDialog, showLoadingDialog } from './dialogs.js';
export const version = "v3.0.0";
export const BASE_URL = 'https://host2.biblegpt-la.com';
export const AUTHORIZATION_SECRET_KEY = 'MzFfcdFLLhnxY80YEvMIJzs08dBekZCzqZBcMDwXChcoIZA';
export const CURRENT_PAGE = getCurrentPageName();
export const CONSOLE_MODE = true;

export const localStorageKey = {
  user_data: `${version}_user_data`,
  top_question: `${version}_top_questions`,
  user_history: `${version}_user_history`
};

export const cookieKey = {
  model_data: `${version}_model_data`,
}

// Get current page URL
if (CONSOLE_MODE) console.log("Current Page:", `[${CURRENT_PAGE}]`);

export let PROFILE_DATA_JSON = "";

export const SUBSCRIPTION_STATUS = {
    1: "Active",
    2: "Cancelled",
    3: "Pending",
    4: "Expired",
    5: "Suspended",
    6: "Trial"
};

export const SESSION_STATUS = {
  canceled: "Your session has been canceled. If you need further assistance, please contact support.",
  complete: "Thank you for subscribing to our plan. For more details about your subscription, please visit your profile page.",
  completed: "Thank you for subscribing to our plan. For more details about your subscription, please visit your profile page.",
  incomplete: "Your session is incomplete. Please make sure to complete all required steps to proceed.",
  processing: "Your session is currently being processed. Please wait while we complete your request."
};

document.addEventListener("DOMContentLoaded", function() {
    let profileData = localStorage.getItem(localStorageKey.user_data);
    PROFILE_DATA_JSON = decodeJWT(profileData);
    if (CONSOLE_MODE) console.log("Decode Object in module.js [PROFILE_DATA_JSON]:", PROFILE_DATA_JSON);
});


// export function showLoadingDialog(){
//   var loadingDialog = document.getElementById("loading-dialog");
//   if(loadingDialog){
//     loadingDialog.style.display = "block";
//   }
// }

// export function hideLoadingDialog(){
//   var loadingDialog = document.getElementById("loading-dialog");
//   if(loadingDialog){
//     loadingDialog.style.display = "none";
//   }
// }

export async function getUserInfoIP() {
  try {
      const response = await fetch('https://ipinfo.io/json');
      const data = await response.json();
      if (data) {
        return data;
      } else {
        return null;
      }
  } catch (error) {
      console.error('Error:', error);
      return null;
  }
}

// Example usage with async/await
export async function getCountryCodeByIP() {
  try {
    const data = await getUserInfoIP();
    if (data && data.country) {
      //if (CONSOLE_MODE) console.log("Current country:", data.country);
      return data.country;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export async function generateUserID() {
  // Call getUserInfoIP and handle the response using .then()
  return getUserInfoIP().then(userInfo => {
      // Extract relevant user information
      const { country, ip, loc } = userInfo;

      // Concatenate relevant information
      const concatenatedString = country + ip + loc;

      // Hash the concatenated string to create a unique identifier using MD5 (or any other preferred hash function)
      const hashedUserID = CryptoJS.MD5(concatenatedString).toString();

      return hashedUserID;
  });
}

export function deleteUserHistory() {
  // Attempt to get the item from local storage
  const userHistory = localStorage.getItem(localStorageKey.user_history);
  
  // Check if the item exists
  if (userHistory !== null) {
    // Remove the item if it exists
    localStorage.removeItem(localStorageKey.user_history);
  }
}

export function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
}

// Get all elements with class 'input100'
const inputElements = document.querySelectorAll('.input100');
if (inputElements){
    // Loop through each input element
    inputElements.forEach(inputElement => {
        // Add blur event listener
        inputElement.addEventListener('blur', function() {
            // Check if the input value is not empty after trimming whitespace
            if (this.value.trim() !== "") {
                // If not empty, add class 'has-val'
                this.classList.add('has-val');
            } else {
                // If empty, remove class 'has-val'
                this.classList.remove('has-val');
            }
        });
    });
}

export function togglePassword() {
    var passwordInput = document.getElementById("password");
    var showPwCheckbox = document.getElementById("show-pw");
    if (showPwCheckbox.checked) {
        passwordInput.type = "text";
    } else {
        passwordInput.type = "password";
    }
}

export function togglePasswordConfirmPassword() {
    var passwordInput = document.getElementById("password");
    var confirmPasswordInput = document.getElementById("confirmPassword");
    var showPwCheckbox = document.getElementById("show-pw");
    if (showPwCheckbox.checked) {
        passwordInput.type = "text";
        confirmPasswordInput.type = "text";
    } else {
        passwordInput.type = "password";
        confirmPasswordInput.type = "password";
    }
}

export function showTheCurrentTimer(compareDateStrValue) {
  const compareDate = new Date(compareDateStrValue);
  const now = new Date();
  // Calculate the difference in milliseconds
  const difference = now - compareDate;
  // Convert milliseconds to minutes, hours, and days
  const minutes = Math.floor(difference / (1000 * 60));
  const hours = Math.floor(difference / (1000 * 60 * 60));
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  // Manually format the date string to DD-MM-YYYY
  const day = compareDate.getDate().toString().padStart(2, '0');
  const month = (compareDate.getMonth() + 1).toString().padStart(2, '0');
  const year = compareDate.getFullYear();
  let compareDateString = `${day}-${month}-${year}`;
  const screenWidth = window.innerWidth;
  if(screenWidth < 413){
    compareDateString = `${day}-${month}-${year}`;
  }

  if (CONSOLE_MODE) console.log("compareDateString:", compareDateStrValue);

  if (days < 0) {
    return compareDateStrValue;
  } else if (days == 0) {
    if (minutes < 1) {
      return `Just now`;
    } else if (minutes <= 30) {
      return `${minutes} minutes ago`;
    } else if (hours <= 1) {
      return '1 hour ago.';
    } else if (hours < 24) {
      return 'Today';
    }
  } else if (days == 1) {
    return 'Yesterday';
  } else {
    // Calculate if the event was within the last week
    const oneWeekAgo = new Date(now);
    oneWeekAgo.setDate(now.getDate() - 7);
    if (compareDate > oneWeekAgo) {
      const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return weekdays[compareDate.getDay()];
    } else {
      return `${compareDateString}`;
    }
  }
}

export function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// Function to set a cookie
export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

// Usage
// setCookie("username", "JohnDoe", 30); // This sets a cookie named "username" with the value "JohnDoe" which expires in 30 days

// Function to get a cookie value by name
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
      }
  }
  return null;
}

// Usage
// var username = getCookie("username"); // This retrieves the value of the cookie named "username"
// if (CONSOLE_MODE) console.log(username); // Output: JohnDoe

export function updatePropertyValuebyClass(className, property, value){
  const elements = document.getElementsByClassName(className);
  for (let i = 0; i < elements.length; i++) {
      elements[i][property] = value;
  }
}

export function parseValidStringJSON(message) {
  let jsonData;
  try {
      jsonData = JSON.parse(message);
      // If parsing succeeds, return the parsed JSON data
      return jsonData;
  } catch (e) {
      // If parsing fails, return null
      return null;
  }
}

// Function to shuffle an array
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function decodeJWT(token) {
  if(token){
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  } else {
    return "0"
  }
}
// Get the current page name from the URL
export function getCurrentPageName() {
  var pathArray = window.location.pathname.split('/');
  return pathArray.pop();
}

export function getURLParameterValue(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

export function updateURLParameter(key, value) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  // Update or add the parameter
  params.set(key, value);

  // Set the new query string on the URL object
  url.search = params.toString();

  // Replace the current URL with the updated one
  window.history.replaceState({}, '', url);
}

export function getParamValue(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  // Check if the parameter exists
  if (urlParams.has(paramName)) {
    // Return the value of the parameter
    return urlParams.get(paramName);
  } else {
    // Parameter not found
    if (CONSOLE_MODE) console.log(`Parameter "${paramName}" not found in URL.`);
    return ""
  }
}

export function updateParam(paramName, paramValue) {
  // Get the current URL
  var url = window.location.href;
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set(paramName, paramValue);
  // Update the URL with the new parameter
  var newUrl = url.split('?')[0] + '?' + searchParams.toString();
  // Replace the current URL with the updated URL
  window.history.pushState({path:newUrl},'',newUrl);
}

function decryptPassword(encryptedText, key, iv) {
  // Convert key and iv from Base64 strings to WordArray objects
  const keyHex = CryptoJS.enc.Base64.parse(key);
  const ivHex = CryptoJS.enc.Base64.parse(iv);

  // Decrypt using AES algorithm in CBC mode with PKCS#7 padding
  const decrypted = CryptoJS.AES.decrypt({
    ciphertext: CryptoJS.enc.Hex.parse(encryptedText)
  }, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  // Convert the decrypted WordArray to a UTF-8 string
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function parseJwt(token){
  var base64Url = token.split('.')[1];
  var base64 = decodeURIComponent(atob(base64Url).split('').map((c)=>{
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(base64);
}

export function decodeJWT_USER(token) {
  if(!token){
      if (CONSOLE_MODE) console.log("decodeJWT_USER USER_DATA is empty.");
      return;;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  const payload = JSON.parse(jsonPayload);

  // Check if the token has expired
  if (payload.exp) {
      const expirationTime = payload.exp * 1000; // Convert seconds to milliseconds
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) {
          var currentPageName = getCurrentPageName();
          if (CONSOLE_MODE) console.log(currentPageName);
          if(currentPageName == 'sign-in.html'){
              window.location.href = 'sign-in.html';
          }
      }
  }
  return payload;
}

export async function updateProfileReValidate(userID, email){
  if(userID && email){
    try {
      const bodyData = {
          userId: userID,
          email: email
      }
      fetchPostData('/account/re-validate', bodyData)
      .then(data => {
          if (CONSOLE_MODE) console.log(data);
          if(data.status === 200){
              const userData = data.data.userData;
              localStorage.setItem(localStorageKey.user_data, JSON.stringify(userData));
              const decodeDataJSON = decodeJWT(userData);
              if(profileDataJSON){
                profileDataJSON = decodeDataJSON;
              }
              return decodeDataJSON;
          } else {
              alertDialog("alert-login-error", 1, 1, "Go to Profile", "profile.html", "Unable to process", data.message, 100);
              return null;
          }
      })
      .catch(errorMessage => {
          alertDialog("alert-login-catch-error", 1, 1, "Go to Home", "index.html", "An error occurred", errorMessage, 100);
          return null;
      });
    } catch (error){
      alertDialog("alert-login-catch-error", 1, 1, "Go to Home", "index.html", "An error occurred", error, 100);
      return null;
    }
  }
}

export function fetchData(url, options) {
  return new Promise((resolve, reject) => {
      fetch(url, options)
          .then(response => {
              if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
          })
          .then(data => {
              resolve(data);
          })
          .catch(error => {
              reject(error);
          });
  });
}

export function fetchDataFromServer(endPoint, options) {
  const optionHeader = {
      ...options,
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}` // Assuming options.token is where you store your token
      }
  };

  const URL = BASE_URL + endPoint;

  return new Promise((resolve, reject) => {
      fetch(URL, optionHeader)
          .then(response => {
              if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
          })
          .then(data => {
              resolve(data);
          })
          .catch(error => {
              reject(error);
          });
  });
}

// Handle request using POST method
export function fetchPostData(endPoint, bodyData){
    if (CONSOLE_MODE) console.log("bodyData :", bodyData);
    showLoadingDialog();
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}${endPoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}`
        },
        body: JSON.stringify(bodyData)
      })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            hideLoadingDialog();
            resolve(data); // Resolve with success message
          });
        } else {
          response.json().then(data => {
            hideLoadingDialog();
            reject(data.message); // Reject with error message
          });
        }
      })
      .catch(error => {
        alert(error);
        hideLoadingDialog();
        console.error('Error:', error);
        reject('An error occurred while processing your request.'); // Reject with error message
      });
    });
};

// Handle request using GET method
export function fetchGetData(endPoint){
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}${endPoint}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            resolve(data); // Resolve with success message
          });
        } else {
          response.json().then(data => {
            reject(data.message); // Reject with error message
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        reject('An error occurred while processing your request.'); // Reject with error message
      });
    });
};

export function fetchGetDatawithAuth(URL){
  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: 'GET'
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          resolve(data); // Resolve with success message
        });
      } else {
        response.json().then(data => {
          reject(data.message); // Reject with error message
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      reject('An error occurred while processing your request.'); // Reject with error message
    });
  });
};
